export const bikePartsBrands =  {
  id: 'brand',
  label: 'Brand',
  isOpen: false,
  isFilterable: true,
  type: 'DropDownFilter',
  group: 'primary',
  sortingOrder: 6,
  queryParamNames: ['pub_brand'],
  translationGroup: 'brand',
  skipItemPageTranslations: true,
  config: {
    options: [
      { key: 'other', value: 'other', label: 'Other' },
      { key: '8bar', value: '8bar', label: '8Bar' },
      { key: 'a2b', value: 'a2b', label: 'A2B' },
      { key: 'absoluteblack', value: 'absoluteblack', label: 'Absoluteblack' },
      { key: 'academy_bmx', value: 'academy_bmx', label: 'Academy Bmx' },
      { key: 'acros', value: 'acros', label: 'Acros' },
      { key: 'airbone', value: 'airbone', label: 'Airbone' },
      { key: 'airborne', value: 'airborne', label: 'Airborne' },
      { key: 'airdrop', value: 'airdrop', label: 'Airdrop' },
      { key: 'alchemy', value: 'alchemy', label: 'Alchemy' },
      { key: 'alias', value: 'alias', label: 'Alias' },
      { key: 'alienation', value: 'alienation', label: 'Alienation' },
      { key: 'all_in_bmx', value: 'all_in_bmx', label: 'All in Bmx' },
      { key: 'ali_city', value: 'ali_city', label: 'AlI-City' },
      { key: 'alutech', value: 'alutech', label: 'Alutech' },
      { key: 'amber', value: 'amber', label: 'Amber' },
      { key: 'amity', value: 'amity', label: 'Amity' },
      { key: 'and_forks', value: 'and_forks', label: 'And Forks' },
      { key: 'animal', value: 'animal', label: 'Animal' },
      { key: 'apollo', value: 'apollo', label: 'Apollo' },
      { key: 'arc8', value: 'arc8', label: 'Arc8' },
      { key: 'argon_18', value: 'argon_18', label: 'Argon 18' },
      { key: 'ashima', value: 'ashima', label: 'Ashima' },
      { key: 'asista', value: 'asista', label: 'Asista' },
      { key: 'ass_savers', value: 'ass_savers', label: 'Ass Savers' },
      { key: 'atalanta', value: 'atalanta', label: 'Atalanta' },
      { key: 'atomz', value: 'atomz', label: 'Atomz' },
      { key: 'avanti', value: 'avanti', label: 'Avanti' },
      { key: 'aventon', value: 'aventon', label: 'Aventon' },
      { key: 'avid', value: 'avid', label: 'Avid' },
      { key: 'ax_lightness', value: 'ax_lightness', label: 'Ax-Lightness' },
      { key: 'axa', value: 'axa', label: 'Axa' },
      { key: 'bakfiets', value: 'bakfiets', label: 'Bakfiets' },
      { key: 'banshee', value: 'banshee', label: 'Banshee' },
      { key: 'basso', value: 'basso', label: 'Basso' },
      { key: 'batavus', value: 'batavus', label: 'Batavus' },
      { key: 'batch_bicycles', value: 'batch_bicycles', label: 'Batch Bicycles' },
      { key: 'beast_components', value: 'beast_components', label: 'Beast Components' },
      { key: 'bergamont', value: 'bergamont', label: 'Bergamont' },
      { key: 'bianchi', value: 'bianchi', label: 'Bianchi' },
      {
        key: 'bike_ahead_components',
        value: 'bike_ahead_components',
        label: 'Bike Ahead Components',
      },
      { key: 'black_eye', value: 'black_eye', label: 'Black Eye' },
      { key: 'black_inc', value: 'black_inc', label: 'Black Inc' },
      { key: 'blank', value: 'blank', label: 'Blank' },
      { key: 'bombtrack', value: 'bombtrack', label: 'Bombtrack' },
      { key: 'bontrager', value: 'bontrager', label: 'Bontrager' },
      { key: 'bottecchia', value: 'bottecchia', label: 'Bottecchia' },
      { key: 'brakestuff', value: 'brakestuff', label: 'Brakestuff' },
      { key: 'c_bear', value: 'c_bear', label: 'C-Bear' },
      { key: 'cadence', value: 'cadence', label: 'Cadence' },
      { key: 'calibre', value: 'calibre', label: 'Calibre' },
      { key: 'califeira', value: 'califeira', label: 'Califeira' },
      { key: 'campagnolo', value: 'campagnolo', label: 'Campagnolo' },
      { key: 'canfield', value: 'canfield', label: 'Canfield' },
      { key: 'cannondale', value: 'cannondale', label: 'Cannondale' },
      { key: 'canyon', value: 'canyon', label: 'Canyon' },
      { key: 'capgo', value: 'capgo', label: 'Capgo' },
      { key: 'carbon_ti', value: 'carbon_ti', label: 'Carbon-Ti' },
      { key: 'carraro', value: 'carraro', label: 'Carraro' },
      { key: 'ceramicspeed', value: 'ceramicspeed', label: 'Ceramicspeed' },
      { key: 'chromag', value: 'chromag', label: 'Chromag' },
      { key: 'cinema', value: 'cinema', label: 'Cinema' },
      { key: 'clean', value: 'clean', label: 'Clean' },
      { key: 'cleary', value: 'cleary', label: 'Cleary' },
      { key: 'coalition', value: 'coalition', label: 'Coalition' },
      { key: 'colnago', value: 'colnago', label: 'Colnago' },
      { key: 'comas', value: 'comas', label: 'Comas' },
      { key: 'commencal', value: 'commencal', label: 'Commencal' },
      { key: 'continental', value: 'continental', label: 'Continental' },
      { key: 'contura', value: 'contura', label: 'Contura' },
      { key: 'conway', value: 'conway', label: 'Conway' },
      { key: 'corratec', value: 'corratec', label: 'Corratec' },
      { key: 'crankbrothers', value: 'crankbrothers', label: 'Crankbrothers' },
      { key: 'czar', value: 'czar', label: 'Czar' },
      { key: 'daccordi', value: 'daccordi', label: 'Daccordi' },
      { key: 'dahon', value: 'dahon', label: 'Dahon' },
      { key: 'daily_grind_bmx', value: 'daily_grind_bmx', label: 'Daily Grind Bmx' },
      { key: 'dartmoor', value: 'dartmoor', label: 'Dartmoor' },
      { key: 'de_rosa', value: 'de_rosa', label: 'De Rosa' },
      { key: 'deviate', value: 'deviate', label: 'Deviate' },
      { key: 'dia_compe', value: 'dia_compe', label: 'Dia Compe' },
      { key: 'dia_tech', value: 'dia_tech', label: 'Dia Tech' },
      { key: 'diamant', value: 'diamant', label: 'Diamant' },
      { key: 'diamondback', value: 'diamondback', label: 'Diamondback' },
      { key: 'diamondback_uk', value: 'diamondback_uk', label: 'Diamondback Uk' },
      { key: 'duo_brand', value: 'duo_brand', label: 'Duo Brand' },
      { key: 'dynaplug', value: 'dynaplug', label: 'Dynaplug' },
      { key: 'e_bikemanufaktur', value: 'e_bikemanufaktur', label: 'E.Bikemanufaktur' },
      { key: 'early_rider', value: 'early_rider', label: 'Early Rider' },
      { key: 'eastern_bikes', value: 'eastern_bikes', label: 'Eastern Bikes' },
      { key: 'easton', value: 'easton', label: 'Easton' },
      { key: 'eclat', value: 'eclat', label: 'Eclat' },
      { key: 'electra', value: 'electra', label: 'Electra' },
      { key: 'enduro_bearings', value: 'enduro_bearings', label: 'Enduro Bearings' },
      { key: 'enigma', value: 'enigma', label: 'Enigma' },
      { key: 'exustar', value: 'exustar', label: 'Exustar' },
      { key: 'ezra', value: 'ezra', label: 'Ezra' },
      { key: 'fabric', value: 'fabric', label: 'Fabric' },
      { key: 'faction_bike_co', value: 'faction_bike_co', label: 'Faction Bike Co' },
      { key: 'factor', value: 'factor', label: 'Factor' },
      { key: 'failure', value: 'failure', label: 'Failure' },
      { key: 'fairdale', value: 'fairdale', label: 'Fairdale' },
      { key: 'fairdale_bikes', value: 'fairdale_bikes', label: 'Fairdale Bikes' },
      { key: 'falter', value: 'falter', label: 'Falter' },
      { key: 'fantic', value: 'fantic', label: 'Fantic' },
      { key: 'fbm', value: 'fbm', label: 'Fbm' },
      { key: 'federal', value: 'federal', label: 'Federal' },
      { key: 'feldmeier', value: 'feldmeier', label: 'Feldmeier' },
      { key: 'felt', value: 'felt', label: 'Felt' },
      { key: 'festka', value: 'festka', label: 'Festka' },
      { key: 'fezzari', value: 'fezzari', label: 'Fezzari' },
      { key: 'fiction_bmx', value: 'fiction_bmx', label: 'Fiction Bmx' },
      { key: 'fiend', value: 'fiend', label: 'Fiend' },
      { key: 'fit', value: 'fit', label: 'Fit' },
      { key: 'fit_bike_co', value: 'fit_bike_co', label: 'Fit Bike Co' },
      { key: 'fitfito', value: 'fitfito', label: 'Fitfito' },
      { key: 'five_ten', value: 'five_ten', label: 'Five Ten' },
      { key: 'fixie_inc', value: 'fixie_inc', label: 'Fixie Inc.' },
      { key: 'fizik', value: 'fizik', label: 'Fizik' },
      { key: 'flat_tire_defender', value: 'flat_tire_defender', label: 'Flat Tire Defender' },
      { key: 'flow', value: 'flow', label: 'Flow' },
      { key: 'fly_bikes', value: 'fly_bikes', label: 'Fly Bikes' },
      { key: 'flybikes', value: 'flybikes', label: 'Flybikes' },
      { key: 'flyer', value: 'flyer', label: 'Flyer' },
      { key: 'focus', value: 'focus', label: 'Focus' },
      { key: 'foes', value: 'foes', label: 'Foes' },
      { key: 'fondriest', value: 'fondriest', label: 'Fondriest' },
      { key: 'forbidden', value: 'forbidden', label: 'Forbidden' },
      { key: 'formula', value: 'formula', label: 'Formula' },
      { key: 'foundry', value: 'foundry', label: 'Foundry' },
      { key: 'fox', value: 'fox', label: 'Fox' },
      { key: 'free_agent', value: 'free_agent', label: 'Free Agent' },
      { key: 'freed_bike_company', value: 'freed_bike_company', label: 'Freed Bike Company' },
      { key: 'frog_bikes', value: 'frog_bikes', label: 'Frog Bikes' },
      { key: 'fsa', value: 'fsa', label: 'Fsa' },
      { key: 'fuji', value: 'fuji', label: 'Fuji' },
      { key: 'fulcrum', value: 'fulcrum', label: 'Fulcrum' },
      { key: 'funn', value: 'funn', label: 'Funn' },
      { key: 'hartje_manufaktur', value: 'hartje_manufaktur', label: 'Hartje Manufaktur' },
      { key: 'hnf_nicolai', value: 'hnf_nicolai', label: 'Hnf-Nicolai' },
      { key: 'hoffman_bikes', value: 'hoffman_bikes', label: 'Hoffman Bikes' },
      { key: 'joes_no_flats', value: 'joes_no_flats', label: 'Joes No Flats' },
      { key: 'kalkhoff', value: 'kalkhoff', label: 'Kalkhoff' },
      { key: 'lauf', value: 'lauf', label: 'Lauf' },
      { key: 'lynskey_performance', value: 'lynskey_performance', label: 'Lynskey Performance' },
      { key: 'mafia_bikes', value: 'mafia_bikes', label: 'Mafia Bikes' },
      { key: 'magicfruits', value: 'magicfruits', label: 'Magicfruits' },
      { key: 'microshift', value: 'microshift', label: 'Microshift' },
      { key: 'nukeproof', value: 'nukeproof', label: 'Nukeproof' },
      { key: 'procraft', value: 'procraft', label: 'Procraft' },
      { key: 'profile', value: 'profile', label: 'Profile' },
      { key: 'profile_design', value: 'profile_design', label: 'Profile Design' },
      { key: 'profile_racing', value: 'profile_racing', label: 'Profile Racing' },
      { key: 'race_face', value: 'race_face', label: 'Race Face' },
      { key: 'radic_performance', value: 'radic_performance', label: 'Radic Performance' },
      { key: 'rohloff', value: 'rohloff', label: 'Rohloff' },
      { key: 'snafu', value: 'snafu', label: 'Snafu' },
      { key: 'steppenwolf', value: 'steppenwolf', label: 'Steppenwolf' },
      { key: 'tifosi', value: 'tifosi', label: 'Tifosi' },
      { key: 'trailcraft', value: 'trailcraft', label: 'Trailcraft' },
      { key: 'trickstuff', value: 'trickstuff', label: 'Trickstuff' },
      { key: 'wolf_tooth', value: 'wolf_tooth', label: 'Wolf Tooth' },
      { key: 'x_fusion', value: 'x_fusion', label: 'X-Fusion' },
      { key: 'all_city', value: 'all_city', label: 'All-City' },
      { key: 'bmc_bikes', value: 'bmc_bikes', label: 'BMC Bikes' },
      {
        key: 'bombtrack_bikes',
        value: 'bombtrack_bikes',
        label: 'Bombtrack Bikes',
      },
      {
        key: 'cannondale_bikes',
        value: 'cannondale_bikes',
        label: 'Cannondale Bikes',
      },
      {
        key: 'cinelli_bikes',
        value: 'cinelli_bikes',
        label: 'Cinelli Bikes',
      },
      {
        key: 'colnago_bikes',
        value: 'colnago_bikes',
        label: 'Colnago Bikes',
      },
      { key: 'cube_bikes', value: 'cube_bikes', label: 'CUBE Bikes' },
      {
        key: 'dartmoor_bikes',
        value: 'dartmoor_bikes',
        label: 'Dartmoor Bikes',
      },
      { key: 'dmr', value: 'dmr', label: 'DMR' },
      { key: 'falkenjagd_bikes', value: 'falkenjagd_bikes', label: 'Falkenjagd Bikes' },
      { key: 'ns_bikes', value: 'ns_bikes', label: 'NS Bikes' },
      {
        key: 'parapera_bikes',
        value: 'parapera_bikes',
        label: 'Parapera Bikes',
      },
      { key: 'ritchey', value: 'ritchey', label: 'Ritchey' },
      { key: 'salsa_bikes', value: 'salsa_bikes', label: 'Salsa Bikes' },
      {
        key: 'santa_cruz_bikes',
        value: 'santa_cruz_bikes',
        label: 'Santa Cruz Bikes',
      },
      { key: 'scor', value: 'scor', label: 'SCOR' },
      { key: 'scott_bikes', value: 'scott_bikes', label: 'SCOTT Bikes' },
      {
        key: 'specialized_bikes',
        value: 'specialized_bikes',
        label: 'Specialized Bikes',
      },
      {
        key: 'stanton_bikes',
        value: 'stanton_bikes',
        label: 'Stanton Bikes',
      },
      { key: 'surly_bikes', value: 'surly_bikes', label: 'Surly Bikes' },
      { key: 'trek_bikes', value: 'trek_bikes', label: 'Trek Bikes' },
      { key: 'veloheld', value: 'veloheld', label: 'Veloheld' },
      { key: 'wilier', value: 'wilier', label: 'Wilier' },
      { key: 'yeti_cycles', value: 'yeti_cycles', label: 'Yeti Cycles' },
      { key: '3t', value: '3t', label: '3T' },
      { key: 'bbb_cycling', value: 'bbb_cycling', label: 'BBB Cycling' },
      {
        key: 'bike_ahead_composites',
        value: 'bike_ahead_composites',
        label: 'bike ahead composites',
      },
      {
        key: 'blb_equipment',
        value: 'blb_equipment',
        label: 'BLB Equipment',
      },
      { key: 'bmc_parts', value: 'bmc_parts', label: 'BMC Parts' },
      { key: 'brompton', value: 'brompton', label: 'Brompton' },
      { key: 'burgtec', value: 'burgtec', label: 'Burgtec' },
      { key: 'cadex', value: 'cadex', label: 'CADEX' },
      { key: 'cane_creek', value: 'cane_creek', label: 'Cane Creek' },
      { key: 'cinelli', value: 'cinelli', label: 'Cinelli' },
      { key: 'control_tech', value: 'control_tech', label: 'Control Tech' },
      { key: 'cult_parts', value: 'cult_parts', label: 'Cult Parts' },
      {
        key: 'deda_elementi',
        value: 'deda_elementi',
        label: 'Deda Elementi',
      },
      { key: 'dxc', value: 'dxc', label: 'DXC' },
      { key: 'e_thirteen', value: 'e_thirteen', label: 'e*thirteen' },
      { key: 'enve', value: 'enve', label: 'ENVE' },
      { key: 'ergotec', value: 'ergotec', label: 'Ergotec' },
      { key: 'extralite', value: 'extralite', label: 'Extralite' },
      { key: 'gelu', value: 'gelu', label: 'Gelu' },
      {
        key: 'giant_equipment',
        value: 'giant_equipment',
        label: 'Giant Equipment',
      },
      { key: 'hope', value: 'hope', label: 'Hope' },
      { key: 'jagwire', value: 'jagwire', label: 'Jagwire' },
      { key: 'kcnc', value: 'kcnc', label: 'KCNC' },
      { key: 'miche', value: 'miche', label: 'Miche' },
      { key: 'mission', value: 'mission', label: 'Mission' },
      { key: 'monkeylink', value: 'monkeylink', label: 'MonkeyLink' },
      { key: 'moquai', value: 'moquai', label: 'Moquai' },
      { key: 'nc_17', value: 'nc_17', label: 'NC-17' },
      { key: 'newmen', value: 'newmen', label: 'Newmen' },
      { key: 'now8', value: 'now8', label: 'NOW8' },
      { key: 'odyssey', value: 'odyssey', label: 'Odyssey' },
      {
        key: 'pnw_components',
        value: 'pnw_components',
        label: 'PNW Components',
      },
      { key: 'pro', value: 'pro', label: 'PRO' },
      {
        key: 'problem_solvers',
        value: 'problem_solvers',
        label: 'Problem Solvers',
      },
      { key: 'quarq', value: 'quarq', label: 'QUARQ' },
      { key: 'renthal', value: 'renthal', label: 'Renthal' },
      {
        key: 'reverse_components',
        value: 'reverse_components',
        label: 'Reverse Components',
      },
      { key: 'rfr', value: 'rfr', label: 'RFR' },
      { key: 'salt', value: 'salt', label: 'Salt' },
      {
        key: 'schmolke_carbon',
        value: 'schmolke_carbon',
        label: 'Schmolke Carbon',
      },
      {
        key: 'sdg_components',
        value: 'sdg_components',
        label: 'SDG COMPONENTS',
      },
      {
        key: 'simplon_parts',
        value: 'simplon_parts',
        label: 'Simplon Parts',
      },
      {
        key: 'sixpack_racing',
        value: 'sixpack_racing',
        label: 'Sixpack-Racing',
      },
      { key: 'spank', value: 'spank', label: 'Spank' },
      {
        key: 'specialized_equipment',
        value: 'specialized_equipment',
        label: 'Specialized Equipment',
      },
      { key: 'sqlab', value: 'sqlab', label: 'SQlab' },
      { key: 'surly_parts', value: 'surly_parts', label: 'Surly Parts' },
      { key: 'syncros', value: 'syncros', label: 'Syncros' },
      { key: 'syntace', value: 'syntace', label: 'Syntace' },
      { key: 'thm', value: 'thm', label: 'THM' },
      { key: 'thomson', value: 'thomson', label: 'Thomson' },
      { key: 'truvativ', value: 'truvativ', label: 'Truvativ' },
      { key: 'tune', value: 'tune', label: 'Tune' },
      { key: 'vision', value: 'vision', label: 'Vision' },
      {
        key: 'white_industries',
        value: 'white_industries',
        label: 'White Industries',
      },
      { key: 'xlc', value: 'xlc', label: 'XLC' },
      { key: 'zipp', value: 'zipp', label: 'ZIPP' },
      { key: 'boyd_cycling', value: 'boyd_cycling', label: 'Boyd Cycling' },
      { key: 'garmin_tacx', value: 'garmin_tacx', label: 'Garmin Tacx' },
      { key: 'hayes', value: 'hayes', label: 'Hayes' },
      { key: 'k_edge', value: 'k_edge', label: 'K-Edge' },
      { key: 'token', value: 'token', label: 'TOKEN' },
      { key: 'alugear', value: 'alugear', label: 'Alugear' },
      { key: 'birzman', value: 'birzman', label: 'Birzman' },
      { key: 'blackspire', value: 'blackspire', label: 'Blackspire' },
      { key: 'chris_king', value: 'chris_king', label: 'Chris King' },
      {
        key: 'cube_equipment',
        value: 'cube_equipment',
        label: 'Cube Equipment',
      },
      { key: 'dyedbro', value: 'dyedbro', label: 'DYEDBRO' },
      {
        key: 'gates_carbon_drive',
        value: 'gates_carbon_drive',
        label: 'Gates Carbon Drive',
      },
      { key: 'kind_shock', value: 'kind_shock', label: 'Kind Shock' },
      { key: 'kmc', value: 'kmc', label: 'KMC' },
      { key: 'muc_off', value: 'muc_off', label: 'Muc-Off' },
      { key: 'o_symetric', value: 'o_symetric', label: 'O.Symetric' },
      { key: 'pinion', value: 'pinion', label: 'Pinion' },
      { key: 'praxis_works', value: 'praxis_works', label: 'Praxis Works' },
      { key: 'seido', value: 'seido', label: 'SEIDO' },
      {
        key: 'shimano_parts',
        value: 'shimano_parts',
        label: 'Shimano Parts',
      },
      {
        key: 'stages_cycling',
        value: 'stages_cycling',
        label: 'Stages Cycling',
      },
      { key: 'stronglight', value: 'stronglight', label: 'Stronglight' },
      { key: 'sunrace', value: 'sunrace', label: 'SunRace' },
      {
        key: 'ta_specialites',
        value: 'ta_specialites',
        label: 'TA Specialites',
      },
      { key: 'trp', value: 'trp', label: 'TRP' },
      {
        key: 'unior_bike_tools',
        value: 'unior_bike_tools',
        label: 'Unior Bike Tools',
      },
      { key: 'veer', value: 'veer', label: 'Veer' },
      {
        key: 'wheels_manufacturing',
        value: 'wheels_manufacturing',
        label: 'Wheels Manufacturing',
      },
      { key: 'zefal', value: 'zefal', label: 'Zéfal' },
      { key: 'rockshox', value: 'rockshox', label: 'RockShox' },
      {
        key: 'fox_racing_shox',
        value: 'fox_racing_shox',
        label: 'Fox Racing Shox',
      },
      { key: 'manitou', value: 'manitou', label: 'Manitou' },
      { key: 'marzocchi', value: 'marzocchi', label: 'Marzocchi' },
      {
        key: 'dvo_suspension',
        value: 'dvo_suspension',
        label: 'DVO Suspension',
      },
      { key: 'ohlins', value: 'ohlins', label: 'Ohlins' },
      { key: 'dt_swiss', value: 'dt_swiss', label: 'DT Swiss' },
      { key: 'mrp', value: 'mrp', label: 'MRP' },
      { key: 'sr_suntour', value: 'sr_suntour', label: 'SR Suntour' },
      {
        key: 'white_brothers',
        value: 'white_brothers',
        label: 'White Brothers',
      },
      {
        key: 'bos_suspension',
        value: 'bos_suspension',
        label: 'BOS Suspension',
      },
      {
        key: 'push_industries',
        value: 'push_industries',
        label: 'Push Industries',
      },
      {
        key: 'ohlins_racing',
        value: 'ohlins_racing',
        label: 'Öhlins Racing',
      },
      {
        key: 'rst_suspension',
        value: 'rst_suspension',
        label: 'RST Suspension',
      },
      {
        key: 'trust_performance',
        value: 'trust_performance',
        label: 'Trust Performance',
      },
      { key: 'shimano', value: 'shimano', label: 'Shimano' },
      { key: 'sram', value: 'sram', label: 'SRAM' },
      { key: 'magura', value: 'magura', label: 'Magura' },
      {
        key: 'hope_technology',
        value: 'hope_technology',
        label: 'Hope Technology',
      },
      { key: 'bengal', value: 'bengal', label: 'Bengal' },
      { key: 'clarks', value: 'clarks', label: 'Clarks' },
      { key: 'promax', value: 'promax', label: 'Promax' },
      { key: 'galfer', value: 'galfer', label: 'Galfer' },
      { key: 'schwalbe', value: 'schwalbe', label: 'Schwalbe' },
      { key: 'maxxis', value: 'maxxis', label: 'Maxxis' },
      { key: 'michelin', value: 'michelin', label: 'Michelin' },
      { key: 'vittoria', value: 'vittoria', label: 'Vittoria' },
      { key: 'pirelli', value: 'pirelli', label: 'Pirelli' },
      { key: 'kenda', value: 'kenda', label: 'Kenda' },
      { key: 'wtb', value: 'wtb', label: 'WTB' },
      { key: 'panaracer', value: 'panaracer', label: 'Panaracer' },
      { key: 'hutchinson', value: 'hutchinson', label: 'Hutchinson' },
      { key: 'challenge', value: 'challenge', label: 'Challenge' },
      { key: 'irc', value: 'irc', label: 'IRC' },
      { key: 'cst', value: 'cst', label: 'CST' },
      { key: 'teravail', value: 'teravail', label: 'Teravail' },
      { key: 'clement', value: 'clement', label: 'Clement' },
      { key: 'donnelly', value: 'donnelly', label: 'Donnelly' },
      { key: 'compass', value: 'compass', label: 'Compass' },
      { key: 'goodyear', value: 'goodyear', label: 'Goodyear' },
      { key: 'vredestein', value: 'vredestein', label: 'Vredestein' },
      { key: 'mavic', value: 'mavic', label: 'Mavic' },
      {
        key: 'enve_composites',
        value: 'enve_composites',
        label: 'Enve Composites',
      },
      {
        key: 'reynolds_cycling',
        value: 'reynolds_cycling',
        label: 'Reynolds Cycling',
      },
      { key: 'roval', value: 'roval', label: 'Roval' },
      {
        key: 'industry_nine',
        value: 'industry_nine',
        label: 'Industry Nine',
      },
      {
        key: 'stans_notubes',
        value: 'stans_notubes',
        label: "Stan's NoTubes",
      },
      { key: 'hed_cycling', value: 'hed_cycling', label: 'HED Cycling' },
      {
        key: 'easton_cycling',
        value: 'easton_cycling',
        label: 'Easton Cycling',
      },
      { key: 'alexrims', value: 'alexrims', label: 'Alexrims' },
      {
        key: 'hunt_bike_wheels',
        value: 'hunt_bike_wheels',
        label: 'Hunt Bike Wheels',
      },
      {
        key: 'american_classic',
        value: 'american_classic',
        label: 'American Classic',
      },
      {
        key: 'spank_industries',
        value: 'spank_industries',
        label: 'Spank Industries',
      },
      {
        key: 'three_t_cycling',
        value: 'three_t_cycling',
        label: '3T Cycling',
      },
      {
        key: 'thomson_bike_products',
        value: 'thomson_bike_products',
        label: 'Thomson Bike Products',
      },
      { key: 'odi_grips', value: 'odi_grips', label: 'ODI Grips' },
      { key: 'ergon', value: 'ergon', label: 'Ergon' },
      { key: 'lizard_skins', value: 'lizard_skins', label: 'Lizard Skins' },
      { key: 'esi_grips', value: 'esi_grips', label: 'ESI Grips' },
      { key: 'dmr_bikes', value: 'dmr_bikes', label: 'DMR Bikes' },
      { key: 'protaper', value: 'protaper', label: 'ProTaper' },
      {
        key: 'deity_components',
        value: 'deity_components',
        label: 'Deity Components',
      },
      { key: 'selle_italia', value: 'selle_italia', label: 'Selle Italia' },
      {
        key: 'brooks_england',
        value: 'brooks_england',
        label: 'Brooks England',
      },
      { key: 'prologo', value: 'prologo', label: 'Prologo' },
      {
        key: 'terry_bicycles',
        value: 'terry_bicycles',
        label: 'Terry Bicycles',
      },
      { key: 'selle_royal', value: 'selle_royal', label: 'Selle Royal' },
      { key: 'ism', value: 'ism', label: 'ISM' },
      { key: 'specialized', value: 'specialized', label: 'Specialized' },
      { key: 'giant', value: 'giant', label: 'Giant' },
      { key: 'serfas', value: 'serfas', label: 'Serfas' },
      { key: 'selle_smp', value: 'selle_smp', label: 'SELLE SMP' },
      { key: 'charge_bikes', value: 'charge_bikes', label: 'Charge Bikes' },
      { key: 'rental', value: 'rental', label: 'Renthal' },
    ]
  },
}
