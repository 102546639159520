export const snowboardGeneralBrands = {
  id: 'brand',
  label: 'Brand',
  isOpen: false,
  isFilterable: true,
  type: 'DropDownFilter',
  group: 'primary',
  sortingOrder: 6,
  queryParamNames: ['pub_brand'],
  translationGroup: 'brand',
  skipItemPageTranslations: true,
  config: {
    options: [
      { key: "686", value: "686", label: "686" },
      { key: "5150", value: "5150", label: "5150" },
      { key: "adidas", value: "adidas", label: "Adidas" },
      { key: "airwalk", value: "airwalk", label: "Airwalk" },
      { key: "allros", value: "allros", label: "Allros" },
      { key: "altitude", value: "altitude", label: "Altitude" },
      { key: "analog", value: "analog", label: "Analog" },
      { key: "anon", value: "anon", label: "Anon" },
      { key: "answer", value: "answer", label: "Answer" },
      { key: "arbor", value: "arbor", label: "Arbor" },
      { key: "arcteryx", value: "arcteryx", label: "Arc'teryx" },
      { key: "armada", value: "armada", label: "Armada" },
      { key: "atomic", value: "atomic", label: "Atomic" },
      { key: "axis", value: "axis", label: "Axis" },
      { key: "backcountry", value: "backcountry", label: "Backcountry" },
      { key: "base_360", value: "base_360", label: "Base 360" },
      { key: "bern", value: "bern", label: "Bern" },
      { key: "billabong", value: "billabong", label: "Billabong" },
      { key: "black_diamond", value: "black_diamond", label: "Black Diamond" },
      { key: "blue", value: "blue", label: "Blue" },
      { key: "board_factory", value: "board_factory", label: "Board Factory" },
      { key: "body_glove", value: "body_glove", label: "Body Glove" },
      { key: "bogner", value: "bogner", label: "Bogner" },
      { key: "bolle", value: "bolle", label: "Bolle" },
      { key: "boulder_gear", value: "boulder_gear", label: "Boulder Gear" },
      { key: "burton", value: "burton", label: "Burton" },
      { key: "capita", value: "capita", label: "CAPiTA" },
      { key: "carson", value: "carson", label: "Carson" },
      { key: "columbia", value: "columbia", label: "Columbia" },
      { key: "core", value: "core", label: "Core" },
      { key: "cycab", value: "cycab", label: "Cycab" },
      { key: "dc", value: "dc", label: "DC" },
      { key: "dakine", value: "dakine", label: "Dakine" },
      { key: "descente", value: "descente", label: "Descente" },
      { key: "deuter", value: "deuter", label: "Deuter" },
      { key: "donek", value: "donek", label: "Donek" },
      { key: "dragon", value: "dragon", label: "Dragon" },
      { key: "drake", value: "drake", label: "Drake" },
      { key: "dynastar", value: "dynastar", label: "Dynastar" },
      { key: "ek_ekcessories", value: "ek_ekcessories", label: "EK Ekcessories" },
      { key: "eagle", value: "eagle", label: "Eagle" },
      { key: "elan", value: "elan", label: "Elan" },
      { key: "electric", value: "electric", label: "Electric" },
      { key: "endeavor", value: "endeavor", label: "Endeavor" },
      { key: "evol", value: "evol", label: "Evol" },
      { key: "five_forty", value: "five_forty", label: "Five Forty" },
      { key: "flow", value: "flow", label: "Flow" },
      { key: "flux", value: "flux", label: "Flux" },
      { key: "flylow", value: "flylow", label: "Flylow" },
      { key: "focus", value: "focus", label: "Focus" },
      { key: "fogtech", value: "fogtech", label: "Fogtech" },
      { key: "forum", value: "forum", label: "Forum" },
      { key: "foursquare", value: "foursquare", label: "Foursquare" },
      { key: "gnu", value: "gnu", label: "GNU" },
      { key: "gt", value: "gt", label: "GT" },
      { key: "girl", value: "girl", label: "Girl" },
      { key: "giro", value: "giro", label: "Giro" },
      { key: "grenade", value: "grenade", label: "Grenade" },
      { key: "head", value: "head", label: "HEAD" },
      { key: "handmade", value: "handmade", label: "Handmade" },
      { key: "holden", value: "holden", label: "Holden" },
      { key: "hotronic", value: "hotronic", label: "Hotronic" },
      { key: "impact", value: "impact", label: "IMPACT" },
      { key: "jeenyus", value: "jeenyus", label: "Jeenyus" },
      { key: "jobe", value: "jobe", label: "Jobe" },
      { key: "jones", value: "jones", label: "Jones" },
      { key: "k2", value: "k2", label: "K2" },
      { key: "kamik", value: "kamik", label: "Kamik" },
      { key: "karakoram", value: "karakoram", label: "Karakoram" },
      { key: "karbon", value: "karbon", label: "Karbon" },
      { key: "kemper", value: "kemper", label: "Kemper" },
      { key: "kombi", value: "kombi", label: "Kombi" },
      { key: "ll_bean", value: "ll_bean", label: "L.L. Bean" },
      { key: "ltd", value: "ltd", label: "LTD" },
      { key: "la_mar", value: "la_mar", label: "La Mar" },
      { key: "landshark", value: "landshark", label: "Landshark" },
      { key: "level", value: "level", label: "Level" },
      { key: "lib_tech", value: "lib_tech", label: "Lib Tech" },
      { key: "liberty", value: "liberty", label: "Liberty" },
      { key: "line", value: "line", label: "Line" },
      { key: "look", value: "look", label: "Look" },
      { key: "mammut", value: "mammut", label: "Mammut" },
      { key: "marhar", value: "marhar", label: "Marhar" },
      { key: "marker", value: "marker", label: "Marker" },
      { key: "marmot", value: "marmot", label: "Marmot" },
      { key: "matrix", value: "matrix", label: "Matrix" },
      { key: "maui_jim", value: "maui_jim", label: "Maui Jim" },
      { key: "millennium", value: "millennium", label: "Millennium" },
      { key: "mission", value: "mission", label: "Mission" },
      { key: "morrow", value: "morrow", label: "Morrow" },
      { key: "mountain_hardware", value: "mountain_hardware", label: "Mountain Hardware" },
      { key: "mystery_ranch", value: "mystery_ranch", label: "Mystery Ranch" },
      { key: "neff", value: "neff", label: "Neff" },
      { key: "never_summer", value: "never_summer", label: "Never Summer" },
      { key: "nidecker", value: "nidecker", label: "Nidecker" },
      { key: "nike", value: "nike", label: "Nike" },
      { key: "nitro", value: "nitro", label: "Nitro" },
      { key: "norrona", value: "norrona", label: "Norrona" },
      { key: "now", value: "now", label: "Now" },
      { key: "oneill", value: "oneill", label: "O'Neill" },
      { key: "osins", value: "osins", label: "O'sins" },
      { key: "oakley", value: "oakley", label: "Oakley" },
      { key: "obermeyer", value: "obermeyer", label: "Obermeyer" },
      { key: "odyssey", value: "odyssey", label: "Odyssey" },
      { key: "omatic", value: "omatic", label: "Omatic" },
      { key: "orange", value: "orange", label: "Orange" },
      { key: "orion_packs", value: "orion_packs", label: "Orion Packs" },
      { key: "osprey", value: "osprey", label: "Osprey" },
      { key: "outdoor_research", value: "outdoor_research", label: "Outdoor Research" },
      { key: "outdoor_tech", value: "outdoor_tech", label: "Outdoor Tech" },
      { key: "poc", value: "poc", label: "POC" },
      { key: "pro", value: "pro", label: "PRO" },
      { key: "pace", value: "pace", label: "Pace" },
      { key: "patagonia", value: "patagonia", label: "Patagonia" },
      { key: "picture_organic", value: "picture_organic", label: "Picture Organic" },
      { key: "pret", value: "pret", label: "Pret" },
      { key: "quest", value: "quest", label: "Quest" },
      { key: "quicksilver", value: "quicksilver", label: "Quicksliver" },
      { key: "rage", value: "rage", label: "RAGE" },
      { key: "rei", value: "rei", label: "REI" },
      { key: "redline", value: "redline", label: "Redline" },
      { key: "reima", value: "reima", label: "Reima" },
      { key: "reusch", value: "reusch", label: "Reusch" },
      { key: "ride", value: "ride", label: "Ride" },
      { key: "rocky_mountain_underground", value: "rocky_mountain_underground", label: "Rocky Mountain Underground" },
      { key: "rome_sds", value: "rome_sds", label: "Rome SDS" },
      { key: "rossignol", value: "rossignol", label: "Rossignol" },
      { key: "roxy", value: "roxy", label: "Roxy" },
      { key: "sp", value: "sp", label: "SP" },
      { key: "sr", value: "sr", label: "SR" },
      { key: "salomon", value: "salomon", label: "Salomon" },
      { key: "sandbox", value: "sandbox", label: "Sandbox" },
      { key: "santa_cruz", value: "santa_cruz", label: "Santa Cruz" },
      { key: "scott", value: "scott", label: "Scott" },
      { key: "sessions", value: "sessions", label: "Sessions" },
      { key: "seven", value: "seven", label: "Seven" },
      { key: "shred", value: "shred", label: "Shred" },
      { key: "signature", value: "signature", label: "Signature" },
      { key: "sims", value: "sims", label: "Sims" },
      { key: "ski_doo", value: "ski_doo", label: "Ski-Doo" },
      { key: "slash", value: "slash", label: "Slash" },
      { key: "slytech", value: "slytech", label: "Slytech" },
      { key: "smartwool", value: "smartwool", label: "SmartWool" },
      { key: "smith", value: "smith", label: "Smith" },
      { key: "soul", value: "soul", label: "Soul" },
      { key: "spark_r_d", value: "spark_r_d", label: "Spark R&D" },
      { key: "spy", value: "spy", label: "Spy" },
      { key: "spyder", value: "spyder", label: "Spyder" },
      { key: "stance", value: "stance", label: "Stance" },
      { key: "surface", value: "surface", label: "Surface" },
      { key: "sweet_protection", value: "sweet_protection", label: "Sweet Protection" },
      { key: "switchback", value: "switchback", label: "Switchback" },
      { key: "swix", value: "swix", label: "Swix" },
      { key: "system", value: "system", label: "System" },
      { key: "time", value: "time", label: "TIME" },
      { key: "tail", value: "tail", label: "Tail" },
      { key: "technine", value: "technine", label: "Technine" },
      { key: "the_north_face", value: "the_north_face", label: "The North Face" },
      { key: "thirty_two", value: "thirty_two", label: "Thirty Two" },
      { key: "tour", value: "tour", label: "Tour" },
      { key: "transition", value: "transition", label: "Transition" },
      { key: "transpack", value: "transpack", label: "Transpack" },
      { key: "true", value: "true", label: "True" },
      { key: "ultimate", value: "ultimate", label: "Ultimate" },
      { key: "vans", value: "vans", label: "Vans" },
      { key: "voile", value: "voile", label: "Voile" },
      { key: "volcom", value: "volcom", label: "Volcom" },
      { key: "volkl", value: "volkl", label: "Volkl" },
      { key: "von_zipper", value: "von_zipper", label: "Von Zipper" },
      { key: "wsd", value: "wsd", label: "WSD" },
      { key: "wall", value: "wall", label: "Wall" },
      { key: "wildhorn", value: "wildhorn", label: "WildHorn" },
      { key: "worth", value: "worth", label: "Worth" },
      { key: "other", value: "other", label: "Other" }
    ]
  }
}
