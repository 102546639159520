import {
  bikePartsBrands,
  bikesBrands,
  skiBootsBrands,
  skiGeneralBrands,
  snowboardGeneralBrands,
  snowHelmetsBrands
} from "./brands";


export const brandsConfig = {
  'bike': bikesBrands,
  'parts': bikePartsBrands,

  'skis_alpine': skiGeneralBrands,
  'skis_touring_backcountry': skiGeneralBrands,
  'skis_cross_country': skiGeneralBrands,
  'skis_park_pipe': skiGeneralBrands,
  'skis_boots': skiBootsBrands,
  'skis_bindings': skiGeneralBrands,
  'skis_poles': skiGeneralBrands,

  'snowboard': snowboardGeneralBrands,
  'snowboard_boots': snowboardGeneralBrands,
  'snowboard_bindings': snowboardGeneralBrands,

  'helmets': snowHelmetsBrands,
  'goggles': snowHelmetsBrands,
}
