export const skiGeneralBrands = {
  id: 'brand',
  label: 'Brand',
  isOpen: false,
  isFilterable: true,
  type: 'DropDownFilter',
  group: 'primary',
  sortingOrder: 6,
  queryParamNames: ['pub_brand'],
  translationGroup: 'brand',
  skipItemPageTranslations: true,
  config: {
    options: [
      { key: "other", value: "other", label: "Other" },
      { key: "2xu", value: "2xu", label: "2XU" },
      { key: "4frnt", value: "4frnt", label: "4FRNT" },
      { key: "airwalk", value: "airwalk", label: "Airwalk" },
      { key: "allros", value: "allros", label: "Allros" },
      { key: "alpina", value: "alpina", label: "Alpina" },
      { key: "anon", value: "anon", label: "Anon" },
      { key: "arcteryx", value: "arcteryx", label: "Arc'teryx" },
      { key: "arctica", value: "arctica", label: "Arctica" },
      { key: "armada", value: "armada", label: "Armada" },
      { key: "artech", value: "artech", label: "Artech" },
      { key: "athalon", value: "athalon", label: "Athalon" },
      { key: "atomic", value: "atomic", label: "Atomic" },
      { key: "auclair", value: "auclair", label: "Auclair" },
      { key: "augment", value: "augment", label: "Augment" },
      { key: "axis", value: "axis", label: "Axis" },
      { key: "backcountry", value: "backcountry", label: "Backcountry" },
      { key: "base_360", value: "base_360", label: "Base 360" },
      { key: "beast", value: "beast", label: "Beast" },
      { key: "billabong", value: "billabong", label: "Billabong" },
      { key: "black_crows", value: "black_crows", label: "Black Crows" },
      { key: "black_diamond", value: "black_diamond", label: "Black Diamond" },
      { key: "bliz", value: "bliz", label: "Bliz" },
      { key: "blizzard", value: "blizzard", label: "Blizzard" },
      { key: "blue", value: "blue", label: "Blue" },
      { key: "bogner", value: "bogner", label: "Bogner" },
      { key: "bolle", value: "bolle", label: "Bolle" },
      { key: "bomber", value: "bomber", label: "Bomber" },
      { key: "booster", value: "booster", label: "Booster" },
      { key: "brenter", value: "brenter", label: "Brenter" },
      { key: "briko", value: "briko", label: "Briko" },
      { key: "burton", value: "burton", label: "Burton" },
      { key: "carrera", value: "carrera", label: "Carrera" },
      { key: "colmar", value: "colmar", label: "Colmar" },
      { key: "columbia", value: "columbia", label: "Columbia" },
      { key: "dps", value: "dps", label: "DPS" },
      { key: "dainese", value: "dainese", label: "Dainese" },
      { key: "dakine", value: "dakine", label: "Dakine" },
      { key: "dalbello", value: "dalbello", label: "Dalbello" },
      { key: "dale_of_norway", value: "dale_of_norway", label: "Dale of Norway" },
      { key: "daleboot", value: "daleboot", label: "DaleBoot" },
      { key: "defiance", value: "defiance", label: "Defiance" },
      { key: "descente", value: "descente", label: "Descente" },
      { key: "deuter", value: "deuter", label: "Deuter" },
      { key: "diadora", value: "diadora", label: "Diadora" },
      { key: "dolomite", value: "dolomite", label: "Dolomite" },
      { key: "dragon", value: "dragon", label: "Dragon" },
      { key: "dynafit", value: "dynafit", label: "Dynafit" },
      { key: "dynamic_discs", value: "dynamic_discs", label: "Dynamic Discs" },
      { key: "dynastar", value: "dynastar", label: "Dynastar" },
      { key: "eagle", value: "eagle", label: "Eagle" },
      { key: "eddie_bauer", value: "eddie_bauer", label: "Eddie Bauer" },
      { key: "elan", value: "elan", label: "Elan" },
      { key: "electric", value: "electric", label: "Electric" },
      { key: "energiapura", value: "energiapura", label: "Energiapura" },
      { key: "faction", value: "faction", label: "Faction" },
      { key: "fischer", value: "fischer", label: "Fischer" },
      { key: "flylow", value: "flylow", label: "Flylow" },
      { key: "fritschi", value: "fritschi", label: "Fritschi" },
      { key: "full_tilt", value: "full_tilt", label: "Full Tilt" },
      { key: "fuxi", value: "fuxi", label: "Fuxi" },
      { key: "g3", value: "g3", label: "G3" },
      { key: "gpo", value: "gpo", label: "GPO" },
      { key: "gt", value: "gt", label: "GT" },
      { key: "gabel", value: "gabel", label: "Gabel" },
      { key: "giant", value: "giant", label: "Giant" },
      { key: "giro", value: "giro", label: "Giro" },
      { key: "goode", value: "goode", label: "Goode" },
      { key: "gordini", value: "gordini", label: "Gordini" },
      { key: "head", value: "head", label: "HEAD" },
      { key: "halit", value: "halit", label: "Halit" },
      { key: "handmade", value: "handmade", label: "Handmade" },
      { key: "harvest", value: "harvest", label: "Harvest" },
      { key: "helly_hansen", value: "helly_hansen", label: "Helly Hansen" },
      { key: "hestra", value: "hestra", label: "Hestra" },
      { key: "high_sierra", value: "high_sierra", label: "High Sierra" },
      { key: "holmenkol", value: "holmenkol", label: "Holmenkol" },
      { key: "hot_chillys", value: "hot_chillys", label: "Hot Chillys" },
      { key: "id_one", value: "id_one", label: "ID One" },
      { key: "icelantic", value: "icelantic", label: "Icelantic" },
      { key: "j_skis", value: "j_skis", label: "J Skis" },
      { key: "jiberish", value: "jiberish", label: "Jiberish" },
      { key: "k2", value: "k2", label: "K2" },
      { key: "karbon", value: "karbon", label: "Karbon" },
      { key: "kastle", value: "kastle", label: "Kastle" },
      { key: "keka", value: "keka", label: "Keka" },
      { key: "killy", value: "killy", label: "Killy" },
      { key: "kjus", value: "kjus", label: "Kjus" },
      { key: "kombi", value: "kombi", label: "Kombi" },
      { key: "komperdell", value: "komperdell", label: "Komperdell" },
      { key: "ll_bean", value: "ll_bean", label: "LL Bean" },
      { key: "lange", value: "lange", label: "Lange" },
      { key: "leki", value: "leki", label: "Leki" },
      { key: "level", value: "level", label: "Level" },
      { key: "lib_tech", value: "lib_tech", label: "Lib Tech" },
      { key: "liberty", value: "liberty", label: "Liberty" },
      { key: "line", value: "line", label: "Line" },
      { key: "look", value: "look", label: "Look" },
      { key: "louis_garneau", value: "louis_garneau", label: "Louis Garneau" },
      { key: "lululemon", value: "lululemon", label: "Lululemon" },
      { key: "mammut", value: "mammut", label: "Mammut" },
      { key: "marker", value: "marker", label: "Marker" },
      { key: "marmot", value: "marmot", label: "Marmot" },
      { key: "moment", value: "moment", label: "Moment" },
      { key: "mountain_hardware", value: "mountain_hardware", label: "Mountain Hardware" },
      { key: "nike", value: "nike", label: "Nike" },
      { key: "nils", value: "nils", label: "Nils" },
      { key: "nordica", value: "nordica", label: "Nordica" },
      { key: "now", value: "now", label: "Now" },
      { key: "on3p", value: "on3p", label: "ON3P" },
      { key: "oakley", value: "oakley", label: "Oakley" },
      { key: "obermeyer", value: "obermeyer", label: "Obermeyer" },
      { key: "orange", value: "orange", label: "Orange" },
      { key: "orion_packs", value: "orion_packs", label: "Orion Packs" },
      { key: "ortema", value: "ortema", label: "Ortema" },
      { key: "osprey", value: "osprey", label: "Osprey" },
      { key: "outdoor_research", value: "outdoor_research", label: "Outdoor Research" },
      { key: "poc", value: "poc", label: "POC" },
      { key: "pro", value: "pro", label: "PRO" },
      { key: "parlor", value: "parlor", label: "Parlor" },
      { key: "patagonia", value: "patagonia", label: "Patagonia" },
      { key: "phenix", value: "phenix", label: "Phenix" },
      { key: "planks", value: "planks", label: "Planks" },
      { key: "polo", value: "polo", label: "Polo" },
      { key: "praxis", value: "praxis", label: "Praxis" },
      { key: "pro_tec", value: "pro_tec", label: "Pro-Tec" },
      { key: "rmu", value: "rmu", label: "RMU" },
      { key: "reusch", value: "reusch", label: "Reusch" },
      { key: "ride", value: "ride", label: "Ride" },
      { key: "rocky_mountain_underground", value: "rocky_mountain_underground", label: "Rocky Mountain Underground" },
      { key: "romp", value: "romp", label: "Romp" },
      { key: "rossignol", value: "rossignol", label: "Rossignol" },
      { key: "roxa", value: "roxa", label: "Roxa" },
      { key: "roxy", value: "roxy", label: "Roxy" },
      { key: "rusty", value: "rusty", label: "Rusty" },
      { key: "sp", value: "sp", label: "SP" },
      { key: "sr", value: "sr", label: "SR" },
      { key: "srd", value: "srd", label: "SRD" },
      { key: "sync", value: "sync", label: "SYNC" },
      { key: "saga", value: "saga", label: "Saga" },
      { key: "salomon", value: "salomon", label: "Salomon" },
      { key: "scarpa", value: "scarpa", label: "Scarpa" },
      { key: "scott", value: "scott", label: "Scott" },
      { key: "seven", value: "seven", label: "Seven" },
      { key: "shred", value: "shred", label: "Shred" },
      { key: "ski_logik", value: "ski_logik", label: "Ski Logik" },
      { key: "skida", value: "skida", label: "Skida" },
      { key: "skins", value: "skins", label: "Skins" },
      { key: "slalom_customs", value: "slalom_customs", label: "Slalom Customs" },
      { key: "slytech", value: "slytech", label: "Slytech" },
      { key: "smartwool", value: "smartwool", label: "SmartWool" },
      { key: "smith", value: "smith", label: "Smith" },
      { key: "soul", value: "soul", label: "Soul" },
      { key: "spy", value: "spy", label: "Spy" },
      { key: "spyder", value: "spyder", label: "Spyder" },
      { key: "stalmach", value: "stalmach", label: "Stalmach" },
      { key: "starter", value: "starter", label: "Starter" },
      { key: "stockli", value: "stockli", label: "Stockli" },
      { key: "strafe", value: "strafe", label: "Strafe" },
      { key: "supra", value: "supra", label: "Supra" },
      { key: "surface", value: "surface", label: "Surface" },
      { key: "sweet_protection", value: "sweet_protection", label: "Sweet Protection" },
      { key: "switchback", value: "switchback", label: "Switchback" },
      { key: "swix", value: "swix", label: "Swix" },
      { key: "system", value: "system", label: "System" },
      { key: "time", value: "time", label: "TIME" },
      { key: "tail", value: "tail", label: "Tail" },
      { key: "tecnica", value: "tecnica", label: "Tecnica" },
      { key: "the_north_face", value: "the_north_face", label: "The North Face" },
      { key: "toko", value: "toko", label: "Toko" },
      { key: "tour", value: "tour", label: "Tour" },
      { key: "transpack", value: "transpack", label: "Transpack" },
      { key: "trek", value: "trek", label: "Trek" },
      { key: "tyrolia", value: "tyrolia", label: "Tyrolia" },
      { key: "uvex", value: "uvex", label: "UVEX" },
      { key: "unbranded", value: "unbranded", label: "Unbranded" },
      { key: "under_armour", value: "under_armour", label: "Under Armour" },
      { key: "van_bergen", value: "van_bergen", label: "Van Bergen" },
      { key: "voile", value: "voile", label: "Voile" },
      { key: "volkl", value: "volkl", label: "Volkl" },
      { key: "von_zipper", value: "von_zipper", label: "Von Zipper" },
      { key: "wsd", value: "wsd", label: "WSD" },
      { key: "whitewood", value: "whitewood", label: "Whitewood" },
      { key: "wildhorn", value: "wildhorn", label: "WildHorn" },
      { key: "wintersteiger", value: "wintersteiger", label: "Wintersteiger" },
      { key: "yuki", value: "yuki", label: "Yuki" },
      { key: "zanier", value: "zanier", label: "Zanier" },
      { key: "zeal", value: "zeal", label: "Zeal" },
      { key: "ziener", value: "ziener", label: "Ziener" },
      { key: "zipline", value: "zipline", label: "Zipline" },
    ]
  }
}
